<template>
  <div id="shoppingHistory">
    <div class="searchForm">
        <div>
            会员姓名： <el-input size="small" v-model="search_form.member_name" placeholder="请输入会员姓名"  style="width:150px;"></el-input>
        </div>
        <div>
            会员地址： <el-select v-model="search_form.member_address" placeholder="请选择" size="small">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.label">
                            </el-option>
                        </el-select>
        </div>
        <div>
            会员手机号： <el-input size="small" v-model="search_form.member_phone" placeholder="请输入会员手机号"  style="width:150px;"></el-input>
        </div>
        <div>
            添加时间：  <el-date-picker
                size="small"
                v-model="search_form.time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
        </div>
        <div>
           状态：
                <el-radio v-model="search_form.is_check" label="">不限</el-radio>
                <el-radio v-model="search_form.is_check" label="0">未付款</el-radio>
                <el-radio v-model="search_form.is_check" label="1">已付款</el-radio>
        </div>
        <div>
            <el-button type="primary" size="mini" @click="search">检索</el-button>
            <el-button size="mini" @click="clearSearch">重置/检索</el-button>
        </div>
        <br>
        <el-button style="float: right;" type="primary" size="mini" @click="downlode">下载</el-button>
        <div style="clear: both"></div>
    </div>

    <br>

    <el-table
        :data="orderList"
        style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="padding: 10px;">
                <el-table :data="props.row.data"  border style="width: 100%" >
                    <el-table-column  prop="good_name"  label="商品名称" align="center"> </el-table-column>
                    <el-table-column   label="商品图片" align="center">
                      <template slot-scope="scope">
						  <bigImg :furl="goodsImgUrl+scope.row.good_img" width="100px" height="100px"></bigImg>
                      </template>
                    </el-table-column>
                    	<el-table-column   label="商品单价" align="center">
                         <template slot-scope="scope">
                             {{scope.row.good_price}}元/{{scope.row.unit}}
                         </template>
                      </el-table-column>
                      <el-table-column  prop="num"  label="数量" align="center"> </el-table-column>
                    	<el-table-column  prop="total"  label="小计" align="center">  </el-table-column>
                 </el-table>
            </div>

          </template>
        </el-table-column>
         <el-table-column  prop="order_number"  label="订单编号" align="center"> </el-table-column>
    	 <el-table-column  prop="member_name"  label="会员姓名" align="center"> </el-table-column>
    	<el-table-column  prop="member_phone"  label="会员手机号" align="center"> </el-table-column>
      <el-table-column  prop="member_address"  label="会员地址" align="center"> </el-table-column>
      <el-table-column label="添加时间" width="180"  align="center" >
        <template slot-scope="scope">
           <div>
			  {{scope.row.add_time | formatTime}}
           </div>
        </template>
      </el-table-column>
        <el-table-column label="总计金额" align="center">
			<template slot-scope="scope">
			   <div>
				  <div>
					{{scope.row.sum}}
				  </div>
				  <!-- <div>
					<span>优惠金额:</span>
					{{scope.row.discount_amount}}
				  </div>
				  <div>
					 <span>实收金额:</span>
					 {{(scope.row.sum-scope.row.discount_amount).toFixed(2)}}
				  </div> -->
			   </div>
			</template>
		</el-table-column>
		<el-table-column label="优惠金额" align="center">
			<template slot-scope="scope">
			   <div>
				  <div>
					{{scope.row.discount_amount}}
				  </div>
				  <!-- <div>
					 <span>实收金额:</span>
					 {{(scope.row.sum-scope.row.discount_amount).toFixed(2)}}
				  </div> -->
			   </div>
			</template>
		</el-table-column>
		<el-table-column label="实收金额" align="center">
			<template slot-scope="scope">
			   <div>
				  <div>
					 {{(scope.row.sum-scope.row.discount_amount).toFixed(2)}}
				  </div>
			   </div>
			</template>
		</el-table-column>
        <el-table-column label="收款人"  prop="check_person" align="center"></el-table-column>
        <el-table-column   label="状态" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.is_check"   size="small" 
				style="width: 100px;" @change="updateCheck(scope)">
                <el-option
                  v-for="item in checked"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
        </el-table-column>
		<!-- <el-table-column  label="操作"  fixed="right"  align="center">
		    <template slot-scope="scope">
		        <el-button v-if="scope.row.is_check==0" type="text" size="small" @click="del(scope)">删除</el-button>
		    </template>
		</el-table-column> -->
      </el-table>
    <div class="page">
        <el-pagination
                background
                @size-change="pageChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page_totle">
        </el-pagination>
    </div>

  </div>
</template>

<script>
  export default{
    data(){
      return {
        search_form:{
          member_name:'',
          member_address:'',
          member_phone:'',
          place_order_person:'',
          time:[],
          start_time:'',
          end_time:'',
          is_check:'',
        },
        options:[],
        page:1,
        pageSize:20,
        orderList:[],
        page_totle:0,
        checked:[
          {value:'0',name:'未付款'},
          {value:'1',name:'已付款'},
        ],
      }
    },
    mounted(){
      document.title = '线上订单'
      this.getAddress()
      this.getMemberShoppingHistory()
    },
    methods:{
		del(el){
		    let _this=this
		    if(confirm('确定删除')){
		        var index = el.$index
		        var id=el.row.id
				var order_number = el.row.order_number
		        this.$post('/pc/member/delMemberOrder',{id,order_number},function (res) {
		            let result = res.data
		            if(result.code==200){
		                _this.orderList.splice(index,1)
		                _this.page_totle--
		                _this.$message.success(result.msg)
		            }
		        })
		    }
		},
      updateCheck(scope){
        this.orderList[scope.$index].check_person = '管理员'
         var el = scope.row
          this.$post('/pc/member/updateMemberOrder',{order_number:el.order_number,is_check:el.is_check,check_person:'管理员'},res=>{
               this.$message.success('更新成功')
          })
		   let order_totle = el.sum-el.discount_amount
		  let params = {
			  order_number:el.order_number,
			  is_check:el.is_check,
			  order_totle:order_totle,
			  mid:el.mid,
			  phone:el.member_phone,
			  uname:el.member_name,
			  order_id:el.id,
			  handle_person:'管理员'
		  }
		  this.$post('/pc/member/addOrderScoreWhiter',params,res=>{
			  
		  })
      },
      getType(num,arr){
        var array = JSON.parse(arr)
        return array[num]
      },
      pageChange(pageSize){
          this.page=1
          this.pageSize=pageSize
          this.getMemberShoppingHistory()
      },
      handleCurrentChange(page){
          this.page=page
          this.getMemberShoppingHistory()
      },
      getMemberShoppingHistory(){
        let _this=this
        _this.search_form.page=_this.page
        _this.search_form.pageSize=_this.pageSize
         this.$post('/pc/member/getMemberPlaceOrder',_this.search_form,function(res){
              let result = res.data
              if(result.code==200){
                  _this.orderList=result.data.res
                  _this.page_totle=result.data.count

              }
         })
      },
      getAddress(){
          let _this=this
          this.$post('/pc/member/getAddress',{},function (res) {
              let result = res.data
              if(result.code==200){
                  _this.$message.success(result.msg)
                  if(result.data){
                      let address = result.data.member_address
                      let len = address.length - 1
                      address = address.slice(0,len)
                      let arr = address.split(',')

                      for(let i in arr){
                          _this.options.push({
                              value:i,
                              label:arr[i]
                          })
                      }
                  }
              }
              if(result.code==401){
                 _this.$router.push({path:'/'})
              }
          })
      },
      search(){
          let _this = this
          if(this.search_form.time.length){
              this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
              this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
          }else{
              this.search_form.start_time = ''
              this.search_form.end_time = ''
          }
          _this.getMemberShoppingHistory()
      },
      clearSearch(){
              this.search_form.member_name=''
              this.search_form.member_address=''
              this.search_form.place_order_person = ''
              this.search_form.member_phone=''
              this.search_form.time=[]
              this.search_form.start_time=''
              this.search_form.end_time=''
              this.search_form.is_check = ''
              this.page=1
              this.pageSize=20
              this.getMemberShoppingHistory()
      },
      downlode(){
        let _this = this
         if(this.search_form.time.length){
             this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
             this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
         }else{
             this.search_form.start_time = ''
             this.search_form.end_time = ''
         }

         let cid = sessionStorage.getItem('cid')



         if(this.search_form.member_name){
             var member_name = '&member_name=' + this.search_form.member_name
         }else{
             var member_name = '&member_name='
         }

         if(this.search_form.member_address){
             var member_address = '&member_address=' + this.search_form.member_address
         }else{
            var member_address = '&member_address='
         }

         if(this.search_form.is_check){
             var is_check = '&is_check=' + this.search_form.is_check
         }else{
            var is_check = '&is_check='
         }

         if(this.search_form.member_phone){
             var member_phone = '&member_phone=' + this.search_form.member_phone
         }else{
            var  member_phone = '&member_phone='
         }

         if(this.search_form.start_time){
             var start_time = '&start_time=' + this.search_form.start_time
         }else{
             var start_time = '&start_time='
         }

         if(this.search_form.end_time){
             var end_time = '&end_time=' + this.search_form.end_time
         }else{
             var end_time = '&end_time='
         }

         var token = sessionStorage.getItem('token')
          token = '&token=' + token

       let url = `${_this.$domain}/pc/excel/excelMembeOrder?cid=${cid}${member_name}${member_address}${member_phone}${start_time}${end_time}${is_check}${token}`
        location.href = url
      },
    }
  }
</script>

<style>
  #shoppingHistory .table .header>div{
    margin-right: 30px;

  }
</style>
